.wordpress-post-view{

    .post-main{

        .page-title{
            h1{
                margin: 0 0 5px;
            }
        }

        .post-content{
            font-size: 18px;

            h2,h3{
                color: $gray;
                border-bottom: 1px solid $lead;
                margin: 0 0 10px;
                padding: 0 0 10px;
            }

            .gallery, #gallery-1{
                margin: 0 -10px 25px;

                .gallery-item{
                    padding: 10px;
                    margin: 0;

                    a{
                        display: block;
                        position: relative;
                        border: 1px solid $lightGray;
                        padding: 30px 10px;

                        &:before{
                            @media (min-width: $screen-md-min){
                                content: "+";
                            }
                            position: absolute;
                            background-color: rgba(0,0,0,.1);
                            top: 0;
                            left: 0;
                            z-index: 2;
                            width: 100%;
                            height: 100%;
                            color: $lead;
                            font-size: 180px;
                            opacity: 0;
                            transition: all .3s ease-out;
                            font-weight: $light;
                            line-height: 180px;

                        }

                        &:hover{

                            &:before{
                                opacity: 1;
                            }
                        }
                    }

                    img{
                        border: 0;
                        padding: 0;
                        max-width: 100%;
                        height: auto;
                    }


                }

                &.gallery-columns-1{
                    .gallery-item{
                        width: 100%;
                    }
                }

                &.gallery-columns-2{
                    .gallery-item{
                        width: 50%;
                    }
                }
                &.gallery-columns-3{
                    .gallery-item{
                        width: 50%;

                        @media (min-width: $screen-xs-min){
                            width: 33.33%;
                        }
                    }
                }
            }
        }

        .post-date{
            font-size: 12px;
            font-style: normal;
            margin: 0 0 25px;
        }

        .featured-image{
            border: 0;
            margin: 0 0 45px;
        }
    }

}
