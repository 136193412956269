.products-list-item{
    padding: 40px 25px 25px;
    border: 1px solid $lightGray;
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;

    a{
        display: block;

        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .product-img-box{
        float: none;
        width: 100%;
        padding: 0;

        img{
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    .product-title{
        color: $gray;
        font-size: 13px;
        margin: 0 0 20px;
        font-weight: 400;
        text-transform: none;

        *{
            color: inherit;
        }
    }

    .product-price-wrap{
        margin: auto 0 15px;
        color: $lead-alt;
        font-size: 16px;
        line-height: 1.2;

        .price-box{
            margin: 0;
            .price-excluding-tax, .price-including-tax{
                display: flex;
                align-items: baseline;
                justify-content: center;

                &.price-including-tax{
                    display: none;
                }
                .label{
                    font-size: .8em;
                    order: 2;
                    margin-left: 5px;
                }
            }
        }

        .product-price-wrap-box {
            display: flex;
            justify-content: center;

            span {
                padding-right: .2rem;
            }
        }

        .price-box-label{
            font-size: .8em;
        }
    }

    .actions{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: auto;

        *{
            position: relative;
            z-index: 2;
        }

        .add-to-cart{
            margin-bottom: 10px;
            font-size: 12px;
            padding: 10px 25px;
        }

        .link-wishlist{
            font-size: 12px;
            color: $lead;
            font-weight: $bold;
            text-transform: uppercase;

            i{
                font-size: 18px;
            }

            &:hover{
                color: $lead-alt;
            }
        }
    }


    .overlay{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $lightGray;
        z-index: -1;
        opacity: 0;
        transition: all .3s ease-out;
    }
    *:hover{
        ~ .overlay{
            opacity: 1;
        }
    }
}

.products-list{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 $grid-gutter-width / -2;

    &-item{
        width: 100%;
        margin: 0 $grid-gutter-width / 2 $grid-gutter-width;

        @media (min-width: $screen-xs-min){
            width: calc(50% - #{$grid-gutter-width});
        }

        @media (min-width: $screen-sm-min){
            width: calc(33.33% - #{$grid-gutter-width});
        }

        @media (min-width: $screen-md-min){
            width: calc(25% - #{$grid-gutter-width});
        }
    }

    &.slider{

        .slick-track{
            display: flex;
            height: 100%;
        }
    }
}

.product-list-block{
    position: relative;
    margin-bottom: 30px;

    .slider-arrows{

        @media (min-width: $screen-sm-min){
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
        }

        .slider-arrow{

            @media (min-width: $screen-sm-min){
                position: relative;
                top: 0;
                transform: none;
            }
            @media (min-width: $screen-sm-min){
                margin-left: 10px;
            }
        }
    }
}

.col2-left-layout,.col2-right-layout{
    .col-left ~ .category-products{
        .products-list{
            .products-list-item{
                @media (min-width: $screen-sm-min){
                    width: calc(50% - #{$grid-gutter-width});
                }

                @media (min-width: $screen-md-min){
                    width: calc(33.33% - #{$grid-gutter-width});
                }
            }
        }
    }
}
