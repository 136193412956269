.breadcrumbs{
    margin: 0 0 35px;

    ul{
        padding: 0;
        li{
            font: inherit;
            font-size: 12px;
            font-weight: $light;
            color: $gray;

            a{
                color: $lead;

                &:hover{
                    color: $lead-alt;
                }
            }

            strong{
                font-weight: $light;
            }
        }
    }
}
