.tooltip{
    position: relative;
    &:after{
        content: "";
        border-style: solid;
        border-width: 11px 15px 0 15px;
        border-color: $lead transparent transparent transparent;
        position: absolute;
        top: calc(100% - 1px);
        left: 35px;
    }
}
