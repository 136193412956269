.page{
    padding-bottom: 65px;
    .page-title{
        h1{
            &, body.catalogsearch-result-index &{
                font-size: 30px;
                font-weight: $light;
                border-bottom: 1px solid $lead;
                text-transform: none;
                color: $lead-alt;
                padding-bottom: 10px;
                margin: 0 0 40px;
            }
        }
    }
}
