.is-blog{

    .col-main{

        @media (min-width: $screen-md-min){
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }

    .page-title{
        width: 100%;
    }

    .post-main, .posts-list, .note-msg{
        @media (min-width: $screen-md-min){
            width: 75%;
            padding-right: $grid-gutter-width;
        }
    }

    .posts-list{
        &-item{
            border-bottom: 1px solid $lead;
            padding: 0 0 40px;
            margin: 0 0 45px;
        }
        .post-title{
            font-size: 24px;
            font-weight: $medium;
            margin: 0 0 30px;
        }
        .flex-wrap{

            @media (min-width: $screen-xs-min){
                display: flex;
                margin: 0 $grid-gutter-width / -2;

                > *{
                    padding: 0 $grid-gutter-width / 2;
                }
            }

            .post-image{
                margin-bottom: 25px;
                @media (min-width: $screen-xs-min){
                    margin-bottom: 0;
                    width: 33.33%;
                }
            }

            .post-content{
                @media (min-width: $screen-xs-min){
                    width: 66.66%;
                }
            }
        }

        .post-excerpt{
            margin: 0 0 20px;
            line-height: 1.125;
            font-weight: $light;
        }

        .read-more{
            font-size: 14px;
            font-weight: $medium;
            padding: 10px 20px;
            background-color: #81bc64;
            border-color: #81bc64;


            &:hover{
                background-color: transparent;
                color: #81bc64;
            }
        }

        .count-container{
            display: none;
        }
    }
    .blog-sidebar{
        border: 1px solid $lightGray;
        padding: 25px 15px;

        @media (min-width: $screen-sm-min){
            padding: 25px;
        }

        @media (min-width: $screen-md-min){
            width: 25%;
        }

        .post-block{

            &:not(:last-child){
                padding: 0 0 15px;
                margin-bottom: 25px;
                border-bottom: 1px solid $gray;
            }

            &-heading{
                &-title{
                    margin: 0 0 20px;
                    color: $gray;
                    text-transform: uppercase;
                    font-weight: $light;
                }
            }

            ul{
                line-height: 1.2;
                margin: 0;

                li{
                    margin-bottom: 10px;
                    a{
                        color: $lead;
                        font-size: 14px;
                        display: block;
                    }
                }
            }

            .post-tags-list{
                font-size: 0;
                li{
                    display: inline-block;
                    margin: 0 7px 7px 0;

                    a{
                        background-color: $lead;
                        color: #fff;
                        padding: 8px;
                        font-size: 12px;
                        font-weight: $medium;
                        border: 1px solid $lead;

                        &:hover{
                            color: $lead;
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
}
