.messages{
    padding: 0;

    a{
        color: $lead-alt;
    }

    ul{
        padding: 0;
    }
    .success-msg{
        li{
            border-color: $lead-alt;
            color: #fff;
            background-color: $lead;

            &:before{
                border-left-color: $lead-alt;
            }
        }
    }
}
