.select{
    @extend .icon-down-open;
    position: relative;
    height: 45px;
    width: 150px;
    overflow: hidden;
    border: 1px solid $lead-alt;
    cursor: pointer;

    select{
        position: absolute;
        width: calc(100% + 30px);
        left: 0;
        top: 0;
        height: 45px;
        max-width: none;
        border: 0;
        padding: 0 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    &:before{
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;

        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */

        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;

        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;

        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

        color: $lead;
        font-size: 24px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        margin: 0;
        width: auto;
        border-left: 1px solid $lightGray;
        padding-left: 5px;
        pointer-events: none;
    }

}
