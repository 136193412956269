.form-list .control label, .sp-methods dt label, #checkout-shipping-method-load .sp-methods dd label, #co-shipping-method-form .sp-methods dd label, .product-options ul.options-list label{
    background-color: transparent;
    min-width: 0;
    max-width: 100%;
    width: auto;
}

body:not(.opc-has-progressed-from-login) .opc.opc-firststep-login .section#opc-login .step-title .number{
    width: 26px;
    overflow: visible;
}
body:not(.opc-has-progressed-from-login) .opc.opc-firststep-login .section#opc-login .step-title h2{
    margin-left: 45px;
}
.opc .section.allow .step-title:hover h2, .opc .section.active .step-title h2{
    color: inherit;
}
.opc .section .step-title .number, .opc .section.allow.active .step-title .number, .no-touch .opc .section.allow:hover .step-title .number{
    background-color: transparent;
}


.checkout-onepage-success{
    .page-title{
        margin: 0 0 25px;
        h1{
            margin: 0;
            font-size: 27px;
            color: $lead;
        }
    }

    .sub-title{
        margin: 0 0 10px;
        font-size: 20px;
    }

    .col-main{
        p{
            font-size: 18px;
        }
    }

    .main{
        a{
            color: $lead;

            &:hover{
                color: $lead-alt;
            }
        }
    }
}

.checkout-onepage-index{

    .col-main{
        width: 100%;
        padding-right: 0;
        @media (min-width: $screen-md-min){
            width: 75%;
            padding-right: 20px;
        }
    }

    .col-right {
        width: 25%;

        #checkout-progress-wrapper{
            display: none;

            @media (min-width: $screen-md-min){
                display: block;
            }

            .block-progress.opc-block-progress {
                border-color: $lead;

                .block-title strong {
                    color: $lead;
                    font-family: $font-family;
                }
            }
        }
    }

    fieldset{
        border: 0;
    }

    .form-list{
        fieldset{
            padding: 0;
        }

        li{
            margin-bottom: 15px;
            clear: both;
        }

        .field{
            float: left;
            width: 50%;
            padding-right: 15px;
            padding-left: 15px;

            &:first-of-type,&:first-child{
                padding-left: 0;
            }

            &:last-child{
                padding-right: 0;
            }

            input{
                height: 40px;
                font-size: 16px;
            }

            select{
                height: 40px;
                margin: 0;
            }

        }
    }
}

.opc-block-progress {
    dd {
        font-family: $font-family;

        &, &.complete, address {
            font-style: normal;
        }
    }

    dt.complete a{
        color: $lead;
        font-size: 12px;
        font-weight: $bold;

        &:hover{
            color: $lead-alt;
        }
    }

    table{
        tr{
            td{
                color: inherit;
                font-size: 13px;
            }
        }
    }
}

.opc{
    .section{

        .step-title {
            background-color: $lead;
            color: #fff;
            border-radius: 0;
            transition: all .3s ease-out;

            h2{
                color: inherit;
                text-transform: none;
            }
            .number {
                overflow: visible;
                font-weight: bold;
                color: #fff;
                background-color: transparent;
                border-right: 1px solid rgba(#fff, .2);
            }

            a{
                color: #fff;
                text-transform: uppercase;
                font-weight: $bold;

                i{
                    margin-right: 5px;
                }
            }
        }

        &:hover{
            .step-title {
                background-color: $lead;
                color: #fff;

                h2{
                    color: inherit;
                }

                a{
                    color: $lead;
                }
            }
        }

        &.active{
            .step-title{
                h2{
                    color: #fff;
                }
            }
        }

        &.allow{

            &:not(.active){

                .step-title{
                    &:hover{
                        html.no-touch &{
                            background-color: $lead-alt;
                        }
                    }
                }
            }


            .step-title{
                h2{
                    color: #fff;
                }
                .number{
                    html.no-touch &{
                        background-color: transparent;
                    }
                }
            }

        }

        ul{
            padding: 0;
        }

        .step{
            border-style: solid;
            border-color: $lightGray;
            border-width: 0 1px 1px;
        }
    }

    label{
        font: inherit;
    }

    select{
        border: 1px solid silver;
        font-size: 15px;
        padding: 0 5px;
        height: 30px;
    }

    #co-shipping-method-form .buttons-set .button{
        @extend .button;
    }
}
#checkoutSteps{
    padding: 20px 0;
}
#opc-login{
    #checkout-step-login{
        padding: 0 20px;

        .col2-set{
            .col-1,.col-2{

                @media (max-width: $screen-xs-max){
                    width: 100%;
                    float: none;
                }
            }
        }
    }
}

#checkout-step-payment{
    dd{
        margin: 0;
    }
    fieldset{
        padding: 0;
        margin: 0;
        border: 0;
    }

    .form-list{
        padding: 0;
        margin: 0;
    }

    ul{
        padding-left: 5px;
        @media (min-width: $screen-sm-min){
            padding-left: 20px;
        }

        &.form-list{
            padding-left: 5px;
            @media (min-width: $screen-sm-min){
                padding-left: 15px;
            }
        }


    }
}
#checkout-review-table-wrapper{
    margin-bottom: 30px;
}
#checkout-review-table{
    th{
        background: none;
    }
    tfoot{
        tr{
            background: none;
        }
    }

    .product-name{
        font: inherit;
        text-transform: none;
    }
}

#opc-shipping_method{
    dt{
        font-size: 24px;
        margin: 0 0 20px;
        font-weight: normal;
    }
}
#opc-payment .sp-methods {
    > dd {
        > ul, > p, > div {
            margin-left: 40px !important;
            padding-left: 0 !important;
        }

        > div {
            > p {
                margin-left: 0 !important;
                padding-left: 0 !important;
            }
        }
    }
}

#dt_method_phoenix_cashondelivery {
    margin-bottom: 0;
}

#payment_form_phoenix_cashondelivery {
    margin-top: 5px;
}
#opc-shipping_method #checkout-shipping-method-load, #opc-payment{
    .checkout-methods{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        @media (min-width: $screen-sm-min){
            margin: 0 -15px;
        }
    }
    .checkout-method{
        width: 50%;
        padding: 5px;
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-xs-min){
            width: 25%;
        }

        @media (min-width: $screen-sm-min){
            padding: 15px;
        }
        &--single{
            width: 100%;
            padding: 0;

            @media (min-width: $screen-xs-min){
                width: 50%;
            }
            @media (min-width: $screen-sm-min){
                width: 25%;
            }
        }

        &-inner{
            position: relative;
            text-align: center;
            padding: 15px 10px;
            display: flex;
            flex-direction: column;
            height: 100%;
            font-weight: normal;
            color: $gray;
            font-size: 14px;
        }

        label{
            position: absolute;
            width: 100%;
            height: 100%;
            max-width: 100%;
            left: 0;
            top: 0;
            padding: 0;
            border: 1px solid #ddd;
            cursor: pointer;
            transition: all .3s;

            &:hover{
                background-color: transparent;
                border-color: $lead-alt;
            }
        }

        input{
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            visibility: hidden;

            &:checked + label{
                border-color: $lead;
            }
        }

        &-img{
            padding-bottom: 15px;
            margin: 0 auto;
            max-width: 100%;

            flex-grow: 1;
            display: flex;
            align-items: center;

            @media (min-width: $screen-md-min){
                padding-bottom: 25px;
            }
        }

        &-title{
            font-size: 14px;
            margin-bottom: 25px;
            font-weight: $bold;
        }

        &-info{
            margin-top: auto;
        }

        &-price{

            .price{
                font-size: 24px;
                font-weight: $bold;
                font-family: $font-family;
            }
        }

        &-extra{
            width: 100%;
        }
    }
    .payment-methods{

        .checkout-agreements{
            padding: 10px 5px;
            margin-left: 5px;

            @media (min-width: $screen-sm-min){
                padding: 10px 15px;
                margin-left: 15px;
            }


            .agreement-content{
                overflow: visible;
                background: transparent;
                border: 0;
            }
        }

        .form-list{
            background-color: transparent;
            &:before, &:after{
                display: none;
            }
        }
    }

    #payment_form_wizjo_giftpay{
        border: 0;
    }
}


#checkout-step-review{
    .checkout-agreements{
        margin-bottom: 20px;
        li{
            margin-bottom: 10px;
        }

        .checkbox{
            top: 0;
        }

        a{
            font-weight: inherit;
        }
    }

    .order-review{
        table{
            tr{
                td,th{
                    border: 1px solid $lightGray;
                }
            }
        }
        .item-options{
            font-family: $font-family;
            margin: 0 0 5px 5px;
        }
    }
}
#checkout-review-submit{
    a{
        color: $lead;
        font-weight: $bold;

        &:hover{
            color: $lead-alt;
        }
    }
}
