.block{
    border: 1px solid $lightGray;
    padding: 20px;

    .block-title{
        border-top: 0;

        strong{
            color: $lead;
        }
    }

    ul{
        padding-left: 0;
    }

    li{
        font: inherit;

        a{
            strong{
                color: inherit;
            }
            &:hover{
                color: $lead;
            }
        }

        &.current{
            a{
                color: $lead;
            }
        }
    }

    .btn-remove:after{
        color: $lead;
    }

    .block-content{
        .actions{

            body.customer-account .sidebar &{
                margin-top: 10px;
            }

            .button{
                background-color: $lead;
                color: #fff;
                border: 1px solid $lead;
                margin-bottom: 10px;

                &:hover{
                    color: $lead;
                    background-color: #fff;
                }
            }
        }

    }
}

.sidebar{
    .block{
        border-color: $lightGray;
        &:not(.block-layered-nav){
            padding: 0;

            @media (min-width: 771px){
                padding: 10px;
            }

            @media (min-width: $screen-md-min){
                padding: 20px;
            }

            margin-bottom: 15px;
            .block-title{
                > strong{
                    color: #fff;
                    background-color: $lead;
                    font-family: $font-family;
                    border-width: 0;
                    @media (min-width: 771px){
                        color: $lead;
                        background-color: transparent;
                    }
                    &:after{
                        border-left-color: $lead-alt;
                        top: 45%;
                    }
                }

                &:hover{
                    > strong{
                        background-color: $lead;

                        @media (min-width: 771px){
                            background-color: transparent;
                        }
                    }
                }

                &.active{
                    > strong{
                        color: #fff;
                        border-width: 0;
                        border-bottom: 1px solid $lightGray;
                        background-color: $lead;
                        font-family: $font-family;

                        @media (min-width: 771px){
                            color: $lead;
                            background-color: transparent;
                        }

                        &:after{
                            border-top-color: $lead-alt;
                        }
                    }

                    &:hover{
                        > strong{
                            background-color: $lead;

                            @media (min-width: 771px){
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .block-content{
                border-width: 0;
            }
        }

        &:last-of-type{
            border-bottom: 1px solid $lightGray;
        }
    }
}
