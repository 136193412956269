html{
	font-size: 16px;
}
body{
	font-family: $font-family;
    line-height: 1.428571429;
    font-size: $font-size;
	color: $text-color;
	overflow-x: hidden;
}

.inline-list{
    padding: 0;
    list-style: none;
    font-size: 0;

    li{
        font-size: 0;
        display: inline-block;

        > *{
            font-size: $font-size;
        }
    }
}
.main-container, .footer-container{
    padding: 0;
}
.wrapper{
    width: 1170px;
    max-width: 100%;
    min-height: 0;
    padding: 0 ($grid-gutter-width / 2);
    margin-left: auto;
    margin-right: auto;
    background: transparent;

    &-xl{
        width: 1440px;
    }
}
h2{
    font-size: 28px;
    color: $lead-alt;
    font-weight: $regular;
    text-transform: inherit;
}
h1,h2,h3,h4,h5,h6{
    text-transform: none;
}
ul,ol{
    padding: 0;
}
a{
    color: inherit;
    text-decoration: none;
    transition: all 0.2s;

    &:hover{
        color: inherit;
        text-decoration: none;
    }

    &:focus{
        outline: 0;
        text-decoration: none;
        color: inherit;
    }
}

table{
    width: 100%;
    display: table;

    tr{

        td{
            padding: 3px;
        }
    }
}
.slider {

	.slide {
		visibility: hidden;
		position: absolute !important;
		top: 0;
		left: 0;

		&:first-child {
			visibility: visible;
			position: relative !important;
		}
	}

	&.slick-initialized {

		.slide {
			visibility: visible;
			position: relative !important;
		}
	}
}
.center-block{
    display: block;
    margin: 0 auto;
}
