#footer{
    background-color: $lead-alt;
    color: #fff;
    text-transform: uppercase;
    padding: 55px 0 30px;

    .flex-wrap{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media (min-width: $screen-sm-min){
            align-items: center;
            flex-direction: row;
        }

        > *{
            margin-bottom: 20px;
        }
    }

    .footer-logo{
        @media (min-width: $screen-sm-min){
            margin-right: 40px;
        }
    }

    ul{
        margin: 0;
        padding: 0;

        li{
            font-size: 0;
            margin: 0 0 10px;

            @media (min-width: $screen-sm-min){
                display: inline-block;
                margin: 0 15px 0 0;
            }

            a{
                font-size: 13px;

                &:hover{
                    color: $lead;
                }
            }
        }
    }

    .footer-nav{

    }

    .footer-icons{
        font-size: 13px;

        @media (min-width: $screen-sm-min){
            margin-left: auto;
        }

        span{
            display: inline-block;
            vertical-align: middle;
            margin-bottom: -3px;
        }

        img{
            display: inline-block;
            margin: -3px 0 0 15px;
        }
        li{
            font-size: 13px;

        }
    }

    .copyright{
        width: 100%;
        text-align: right;
        color: #fff;
        font-size: 11px;
        margin: 30px 0 0;

        a{
            color: $lead;
        }
    }
}
