.slider-container{
    position: relative;

    .slider-arrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: $lead;
        font-size: 32px;
        transition: all .3s ease-out;

        &:before{
            margin: 0;
            width: auto;
        }

        &-prev{
            left: $grid-gutter-width / 2;
        }

        &-next{
            right: $grid-gutter-width / 2;
        }

        &:hover{
            color: $lead-alt;
        }
    }

    .slick-dots{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin: 0;
        padding: 0;
        line-height: 0;

        li{
            font-size: 0;
            display: inline-block;
            margin: 0 7px;

            button{
                width: 15px;
                height: 15px;
                background-color: $lead;
                padding: 0;
                font-size: 0;
                border: 0;
                transition: all .3s ease-out;
                outline: 0;
            }

            &.slick-active{
                button{
                    background-color: $lead-alt;
                }

                @media (min-width: $screen-md-min){
                    &:hover{
                        button{
                            background-color: lighten($lead, 10%);
                        }
                    }
                }
            }
        }
    }
}
