form{
    .form-instructions{
        font-family: $font-family;
    }

    input, .input-box input{
        font-size: 16px;
    }

}
