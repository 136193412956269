#header{
    position: relative;
    z-index: 3;
    margin: 0 0 25px;

    .cms-home &{
        margin: 0;
    }
    .flex-wrap{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 25px 0 40px 0;

        z-index: 2;

        @media (min-width: $menu-breakpoint){
            padding-top: 50px;
        }

        background-color: $body-bg;

        @media (min-width: $menu-breakpoint){
            align-items: center;
        }
    }

    .logo{
        min-height: 0;
        float: none;
        width: auto;
        margin: 0 auto 25px;
        padding: 0 10px 0 0;

        @media (min-width: $menu-breakpoint){
            width: 22%;
            margin-bottom: 0;
        }
    }

    .toggle-content{
        display: none;

        @media (max-width: $menu-breakpoint-max){
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
        }
    }

    #header-links{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;

        @media (min-width: $menu-breakpoint){
            width: 78%;
            justify-content: flex-end;
        }

        > *{
            text-align: center;
            flex-grow: 2;

            @media (min-width: $menu-breakpoint){
                margin: 0 10px;
                border: 0;
                flex-grow: 0;
            }

            &:not(:last-child){
                border-right: 1px solid $lead;

                @media (min-width: $menu-breakpoint){
                    border: 0;
                }
            }
        }

        .toggle{
            display: block;
        }

        .header-link{
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            padding: 5px 0;
            color: $lead-alt;

            &:hover{
                color: $lead;
            }
        }

        .link-label{
            font-size: 12px;
            display: none;

            @media (min-width: 500px){
                display: inline-block;
            }
        }

        .link-icon{
            color: $lead;
            margin-right: 5px;
            font-size: 20px;

            @media (min-width: 500px){
                font-size: 18px;
            }
        }



        #header-wishlist{
            @media (max-width: $menu-breakpoint-max){
                display: none;
            }
        }

        #header-logout{
            @media (max-width: $menu-breakpoint-max){
                display: none;
            }

            .link-label{
                @media (max-width: $screen-sm-max){
                    display: none;
                }
            }
        }

        #menu-toggle{
            .link-label{
                display: inline-block;
                font-size: 18px;
            }
            @media (min-width: $menu-breakpoint){
                display: none;
            }
        }

    }

    #header-nav{
        width: 100%;
        padding: 0;

        position: relative;
        z-index: 1;

        @media (min-width: $menu-breakpoint){
            display: block !important;
        }
    }

    #nav{
        background-color: $lead;
        position: relative;
        z-index: 3;

        @media (min-width: $menu-breakpoint){
            display: flex;
            justify-content: center;
        }

        ul{
            padding: 0;
            margin: 0;

            @media (min-width: $menu-breakpoint){
                display: flex;
                justify-content: center;
            }

            li{
                @media (min-width: $menu-breakpoint){
                    margin: 0 10px;
                }
                a{
                    color: #fff;
                    text-transform: uppercase;
                    display: block;
                    padding: 5px 10px;
                    @media (min-width: $menu-breakpoint){
                        padding: 12px 10px;
                    }
                }
            }
        }

        > ul{
            &:before{
                background-color: $lead;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                @media (min-width: $menu-breakpoint){
                    content: "";
                }
            }

            > li{
                > a, > span{
                    display: block;
                    position: relative;
                    z-index: 3;
                    font-size: 16px;
                }
                > a, > span a{
                    &:hover{
                        color: $lead-alt;
                    }

                    &:before{
                        content: "";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 9px 12px 0 12px;
                        border-color: $lead transparent transparent transparent;
                        position: absolute;
                        top: calc(100% - 1px);
                        left: 50%;
                        transform: translate(-50%, -100%);
                        transition: all .3s ease-out;
                    }
                }

                ul{
                    background-color: $lead-alt;
                    padding-left: 20px;
                    width: 100%;
                    flex-wrap: wrap;

                    @media (min-width: $menu-breakpoint){
                        padding: 0;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        transform: translateY(-100%);
                        transition: transform .3s;
                    }

                    li{
                        a{
                            font-size: 12px;

                            &:hover{
                                color: $lead;
                            }
                        }

                        &:first-child{
                            a{
                                padding-top: 12px;
                            }
                        }

                        &:last-child{
                            a{
                                padding-bottom: 12px;
                            }
                        }
                    }
                }

                &.is-open{
                    @media (min-width: $menu-breakpoint){
                        > a{
                            color: $lead-alt;

                            &:before{
                                transform: translate(-50%, 0);
                            }
                        }

                        ul{
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }

    #search-wrap{

        @media (min-width: $menu-breakpoint){
            position: relative;
        }


        #search-toggle{
            @media (min-width: $menu-breakpoint){
                display: none;
            }
            .link-label{
                @media (min-width: $menu-breakpoint){
                    display: none;
                }
            }
        }

        #header-search{
            padding: 10px;
            background-color: $lead;
            overflow: hidden;

            @media (min-width: $menu-breakpoint){
                background-color: transparent;
                display: block;
                position: static;
                width: auto;
                height: auto;
                padding: 0;
                max-width: 185px;
            }

            @media (min-width: $screen-md-min){
                max-width: 250px;
            }

            #search{
                box-sizing: border-box;
                border: 1px solid $lead;
                transition: all .3s ease-out;

                @media (min-width: $menu-breakpoint){
                    border: 0;
                    transform: translateX(calc(100% - 40px));
                }

                &.no-hide{
                    border: 1px solid $lead;
                    transform: translateX(0);
                }
                &:focus{
                    @media (min-width: $menu-breakpoint){
                        border: 1px solid $lead;
                        transform: translateX(0);
                    }
                }
            }

            &:hover{
                #search{
                    @media (min-width: $menu-breakpoint){
                        border: 1px solid $lead;
                        transform: translateX(0);
                    }
                }
            }

            .search-button{
                background: transparent;
                color: $lead;
                font-size: 24px;
                text-indent: 0;
                width: auto;
                height: 40px;
                padding: 7px;
                &:before{
                    display: none;
                }

                @media (min-width: $menu-breakpoint){
                    font-size: 18px;
                }
            }
        }
    }

    #account-wrap{
        @media (min-width: $menu-breakpoint){
            position: relative;
            z-index: 4;
        }
    }

    #header-account{
        background-color: #fff;
        border: 1px solid $lead;

        @media (min-width: $menu-breakpoint){
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        ul{
            padding: 0;
            min-width: 150px;
            margin: 0;
            li{
                a{
                    color: $lead;
                    padding: 3px 10px;
                    border: 0;
                    line-height: inherit;
                    font-size: 14px;

                    &:hover{
                        color: $lead-alt;
                        background-color: transparent;
                    }
                }
            }
        }
    }

    #header-minicart{

        .link-icon{
            margin: 0 3px;
        }

        .minicart-count{
            display: flex;
            align-items: center;

            span{
                color: inherit;
                font: inherit;
                color: inherit;
                font-size: 12px;
                display: inline-block;

                &.count-number{
                    margin-right: 3px;
                }
            }
        }
    }
}
