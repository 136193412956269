.sidebar{
    .block.block-layered-nav{
        border: 1px solid $lightGray;
    }
}

.block-layered-nav{
    padding: 15px 15px 30px;
    border: 1px solid $lightGray;

    @media (min-width: $screen-md-min){
        padding: 25px;
    }

    .block-title{
        display: flex;
        align-items: center;
        margin: 0 0 20px;
        span{
            text-transform: uppercase;
            font-size: 24px;
            font-weight: $light;

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
                font-size: 18px;
            }
        }

        i{
            font-size: 30px;
        }
    }

    .block-subtitle--filter{
        background-color: $lead;
        display: block;
        @media (min-width: $screen-sm-min){
            display: none;
        }
    }

    #narrow-by-list{
        border: 0;
        padding: 10px 0;
        @media (min-width: $screen-sm-min){
            padding: 0;
            display: block !important;
        }
    }

    .currently{
        .block-subtitle{
            font: inherit;
            color: $lead-alt;
            text-transform: none;
        }
        ol{
            padding: 0;
            li{
                font-size: 14px;
                padding-right: 20px;

                .label{
                    color: $lead-alt;
                }
            }
        }

        .btn-remove{
            top: 0;
            margin-top: 0;
        }
    }

    .actions{
        a{
            color: $lead;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            float: none;

            &:hover{
                color: $lead-alt;
            }
        }
    }
    .block-content{
        > dl{
            > dt{
                background: transparent;
                border: 0;
                color: $lead-alt;
                font: inherit;
                font-size: 18px;
                padding: 3px 0 3px 15px;
                font-weight: $regular;
                text-transform: none;

                @media (min-width: $screen-sm-min){
                    padding: 0;
                }

                &:after{
                    left: 3px;
                    border-left-color: $lead;
                    transform: translateY(-10%);

                    @media (min-width: $screen-sm-min){
                        display: none;
                    }
                }

                &:hover{
                    background: transparent;
                }


            }

            > dd{
                border: 0;
                padding: 0;
                display: none;

                @media (min-width: $screen-sm-min){
                    margin-bottom: 10px;
                    display: block;
                }

                ol{
                    padding: 0;
                    margin: 0;

                    @media (min-width: $screen-sm-min){
                        padding: 3px 0;
                    }
                }

                li{
                    color: $gray;
                    font-size: 14px;
                    background: transparent;
                    margin: 0;

                    a{
                        background: transparent;
                    }

                    .count{
                        color: $gray;
                        font-size: 12px;
                    }

                    > *:first-child{
                        display: block;
                        padding: 4px 0 4px 20px;
                        position: relative;

                        &:before{
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            border: 2px solid $lead;
                            background-color: transparent;
                            transition: all .3s ease-out;
                        }
                    }

                    &:not(.m-selected-ln-item){
                        a:hover{
                            &:before{
                                opacity: .5;
                            }
                        }
                    }

                    &.m-selected-ln-item{
                        color: $lead;

                        > *:first-child:before{
                            background-color: $lead;
                        }
                    }
                }
            }
        }

        &.accordion-open{

            > dl{
                > dt{
                    &.current{
                        &:after{
                            left: 1px;
                            border-top-color: $lead;
                        }
                    }
                }
            }
        }
    }
}
