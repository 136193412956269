$font-size: 1rem;

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$italic: italic;

$fallback: 'Arial', serif;
$font-family: 'Ubuntu', $fallback;

$green: #60b833;
$blue: #293382;
$gray: #6a6a6a;
$lightGray: #e5e5e5;

$lead: $green;
$lead-alt: $blue;

$text-color: $gray;

$wrapper: 1170px;
$grid-gutter-width: 30px;

$wrapper-inner-width: $wrapper - 2 * $grid-gutter-width;

$menu-breakpoint: 768px;
$menu-breakpoint-max: $menu-breakpoint - 1;

$screen-xl-min: 1400px;
$screen-lg-min: 1170px;
$screen-md-min: 992px;
$screen-sm-min: 768px;
$screen-xs-min: 480px;

$screen-xs-max: $screen-sm-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
$screen-lg-max: $screen-xl-min - 1;
