.button, .btn, a.button, .button2, .button.button-secondary{
    font: inherit;
    border: 1px solid $lead;
    color: #fff;
    background-color: $lead;
    display: inline-block;
    font-weight: $bold;
    font-size: 12px;
    padding: 15px;
    text-transform: uppercase;
    transition: all .3s;

    &:hover{
        color: $lead;
        background-color: #fff;
    }

    &-inverted, &.button-secondary{
        color: $lead;
        background-color: #fff;

        &:hover{
            background-color: $lead;
            color: #fff;
        }
    }

    span, span span{
        height: auto;
        color: inherit;
        font: inherit;
        text-decoration: none;
    }
}

.btn-remove{
    &:after{
        color: $lead;
    }

    &:hover{
        background-color: $lead;
        color: #fff;
        border-color: $lead;
    }
}
