#catalog-view{
    .main{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .breadcrumbs,
        .search-no-results {
            width: 100%;
            flex-shrink: 0;
            flex-grow: 1;
            flex-basis: 100%;
        }

        @media (min-width: $screen-sm-min){
            flex-direction: row;
        }

        .page-title{
            width: 100%;
            order: 1;
        }

        .category-description {
            width: 100%;
            order: 5;
            margin-bottom: 1rem;
        }

        .note-msg {
            width: 100%;
            order: 10;
        }

        .sidebar{
            order: 20;

            @media (min-width: $screen-sm-min){
                width: 25%;
            }

            ~ .category-products{
                @media (min-width: $screen-sm-min){
                    width: 75%;
                }
            }
        }

        .category-products{
            width: 100%;
            order: 30;

            .pager{
                float: none;
                clear: none;
                text-align: right;
                @media (min-width: $screen-sm-min){
                    position: absolute;
                    top: 57px;
                    right: 0;
                }

                .pages{
                    float: none;
                    display: inline-block;
                }
            }

            .toolbar-bottom{
                .pager{
                    position: static;
                }
            }
        }
    }
}
