.data-table,table{
    tr{

        th{
            background-color: transparent;
        }
        td,th{
            font-family: $font-family;
            font-size: 14px;
            border: 0;
            text-transform: none;

            &:first-child{
            }

            &:last-child{
                color: $lead-alt;
            }
        }

        &:not(:last-child){
            border-bottom: 1px solid $lightGray;
        }
    }
}
