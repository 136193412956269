.checkout-cart-index{
    .cart{
        .page-title{
            border: 0;
            h1{
                float: none;
            }

            ul{
                display: none;
            }
        }
    }

    .flex-wrap{
        display: flex;
        flex-direction: column;
        margin: 0 $grid-gutter-width / -2;

        @media (min-width: $screen-md-min){
            flex-direction: row;
        }

        > *{
            padding: 0 $grid-gutter-width / 2;
        }
    }

    .cart-main{
        @media (min-width: $screen-md-min){
            width: calc(100% - 300px);
        }
    }

    #shopping-cart-table{
        width: 100%;

        tbody{
            tr{
                td{
                    vertical-align: middle;
                    &.product-cart-image{
                        .cart-links{
                            @media only screen and (max-width: 599px){
                                display: none;
                            }
                        }
                    }
                    &.product-cart-info{
                        .product-name{
                            font-size: 16px;
                            text-transform: none;
                            font-weight: $regular;

                            a{
                                &:hover{
                                    color: $lead;
                                }
                            }
                        }

                        .product-cart-sku{
                            display: none;
                        }

                        .item-options{
                            display: none;
                        }
                    }

                    &.product-cart-actions{
                        ul{
                            display: none;
                        }

                        .btn-update{
                            display: none !important;
                        }

                        input{
                            margin: 0;
                        }
                    }
                    a.btn-remove{
                        background: none;
                        text-indent: 0;
                        color: $lead;

                        &:before{
                            content: "X";
                            text-indent: 0;
                            font-family: $font-family;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            line-height: 1;
                            font-size: 16px;
                        }

                        &:hover{
                            color: $lead-alt;
                        }
                    }
                }
            }
        }

        tfoot{
            tr{
                border: 0;
            }
            .cart-footer-actions{
                padding: 20px 0 0;

                > .btn{
                    padding: 0;
                }
            }
            .cart-footer-buttons{
                display: flex;
                flex-wrap: wrap;

                .btn{
                    margin-right: 15px;
                    font-size: 14px;
                    padding: 13px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .cart-sidebar{
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 640px){
            margin: 0 $grid-gutter-width / -2;
        }

        @media (min-width: $screen-md-min){
            flex-direction: column;
            width: 300px;
            margin: 0;
        }
    }

    .cart-totals-wrapper,.cart-forms{
        width: 100%;
        float: none;
        padding: 0;
    }

    .cart-forms{

        @media (min-width: 640px){
            width: 50%;
            padding: 0 $grid-gutter-width / 2;
        }

        @media (min-width: $screen-md-min){
            width: 100%;
            padding: 0;
        }

        #discount-coupon-form{
            .discount{
                background-color: #fff;
                border: 1px solid $lightGray;
                padding: 20px 15px 25px;

                .field-wrapper{
                    display: flex;
                    max-width: 100%;

                    input{
                        width: auto;
                        min-width: 0;
                        height: 41px;
                        font-size: 16px;
                        margin: 0;
                    }
                    .button-wrapper{
                        display: flex;

                        i{
                            &:before{
                                margin: 0;
                            }
                        }
                    }
                    button{
                        margin-left: 10px;
                        padding: 11px;
                    }
                }
            }
        }
    }

    .cart-totals-wrapper{

        @media (min-width: 640px){
            width: 50%;
            padding: 0 $grid-gutter-width / 2;
        }

        @media (min-width: $screen-md-min){
            width: 100%;
            padding: 0;
        }
    }

    .cart-totals{
        background-color: $lead-alt;
        padding: 20px 15px 0;

        table{
            color: #fff;

            tr{
                border: 0;

                td{
                    font-size: 14px;
                    border: 0;
                    text-transform: uppercase;
                    padding: 5px 5px 5px 0;
                    text-align: left;

                    &:last-child{
                        color: #fff;
                        text-align: right;
                        padding: 5px 0 5px 5px;
                    }
                }
            }

            tfoot{
                border: 0;
                tr{
                    td{

                    }
                    &:nth-last-child(2){
                        td{
                            padding-bottom: 15px;
                        }

                    }
                    &:last-child{
                        border-top: 1px solid rgba(#fff, .2);
                        td{
                            padding-top: 15px;
                            strong{
                                font-weight: $bold;
                            }
                        }
                    }
                }
            }
        }

        .checkout-types{
            padding: 0;
            margin: 0 -15px;
            float: none;
            text-align: center;
            max-width: none;

            li{
                margin: 0;
                padding: 0;

                button{
                    width: 100%;
                    margin: 0;
                    text-transform: uppercase;
                    font-size: 18px;
                    padding: 23px 13px;
                }
            }
        }
    }
}
