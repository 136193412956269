.section-heading{
    text-align: center;
    margin: 0 0 40px;

    &-title{
        display: inline-block;
        text-transform: uppercase;
        border-bottom: 1px solid $lead;
        padding: 0 0 10px;
        margin: 0;
        font-size: 24px;
        font-weight: $light;
        color: $lead-alt;
        letter-spacing: 2px;
    }
}
