.news-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 $grid-gutter-width / -2;

    .news-list-item{
        padding: $grid-gutter-width / 2;
        width: 100%;
        display: flex;

        @media (min-width: $screen-xs-min){
            width: 50%;
        }

        @media (min-width: $screen-sm-min){
            width: 33.3333%;
        }

        .news-title{
            margin: 0 auto;
            display: flex;
            align-items: center;
            width: 90%;
            background-color: $lead;
            color: #fff;
            font-size: 14px;
            font-weight: $regular;
            min-height: 33%;
            text-transform: none;
            padding: 10px 20px;
            transition: all .3s;
            margin-top: -40px;
            position: relative;
        }

        a{
            display: flex;
            flex-direction: column;

            &:hover{
                .news-title{
                    background-color: $lead-alt;
                }
            }
        }
    }
}
