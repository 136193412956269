.price-box{
    font: inherit;

    .price{
        font: inherit;
        color: $lead-alt;
        font-size: 16px;
        font-weight: $regular;

        &:after{
            content: " " attr(data-label);
            font-size: .8em;
        }
    }
}
