.product-view{
    .columns{
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-md-min){
            flex-direction: row;
            justify-content: space-between;
            margin: 0 $grid-gutter-width / -2;
        }

        .column{
            @media (min-width: $screen-md-min){
                padding: 0 $grid-gutter-width / 2;
            }

            &-main{
                @media (min-width: $screen-md-min){
                    width: 620px;
                    max-width: 60%;

                    .product-images{
                        display: none;
                    }
                }
            }

            &-extra{
                @media (min-width: $screen-md-min){
                    width: 40%;
                }

                .product-images{
                    margin-bottom: 40px;
                    @media (max-width: $screen-sm-max){
                        display: none;
                    }
                }
            }
        }
    }

    .price-blocks{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px 35px;
        overflow: hidden;

        .price-block{
            min-width: 285px;
            width: 285px;
            max-width: 100%;
            padding: 0 15px;

            @media (min-width: $screen-md-min){
                width: 50%;
            }

            &:first-child{

                @media (min-width: 600px){
                    border-right: 1px solid $lightGray;
                }
            }

            &-logos{
                margin-bottom: 20px;
            }

            &:nth-child(even){

            }

            &-price{
                margin-bottom: 15px;
                .price-box{
                    > span{
                        margin-bottom: 10px;
                        display: flex;
                        align-items: baseline;
                    }
                }

                .label{
                    color: $lead-alt;
                    font-size: 14px;
                }

                .price{
                    order: -1;
                    font-size: 14px;
                    margin-right: 10px;
                }

                &.price-block-giftplus .price-including-tax,
                .price-excluding-tax {
                    margin-bottom: 5px;

                    .label{
                        font-size: 18px;
                    }

                    .price{
                        font-size: 36px;
                        margin-right: 10px;
                        font-weight: $regular;

                        &:after{
                            font-size: .6em;
                        }
                    }
                }

                &.price-block-giftplus .price-excluding-tax {
                    display: none;
                }
            }

            &-info{
                font-size: 14px;
                font-weight: $light;
                line-height: 1.2;

                a{
                    color: $lead;
                    text-transform: uppercase;
                    font-weight: $bold;
                    font-size: 12px;
                }
            }
        }
    }
    h1.product-title{
        color: $lead-alt;
        font-weight: $light;
        font-size: 30px;
        margin: 0 0 45px;
        text-transform: none;
        border: 0;
    }

    .product-images{
        position: relative;
        width: 440px;
        margin: 0 auto 40px;
        max-width: 100%;

        .product-image{
            border: 0;
            margin-bottom: 20px;
        }

        .product-image-gallery {
            margin-bottom: 5px;

            img{
                border: 1px solid $lightGray;
            }
        }

        .thumbs{
            position: relative;
            text-align: center;

            img{
                border: 0;
                display: block;
                margin: 0 auto;
            }

            .thumb-link{
                &:hover{
                    img{
                        border: 0;
                    }
                }
            }

            .slick-list{
                .slick-track{
                    display: flex;
                }

                .slick-slide{
                    padding: 0 10px;
                    outline: 0;

                    div{
                        padding: 5px;
                        cursor: pointer;
                        border: 1px solid $lightGray;
                        transition: all .3s;
                    }

                    &:hover{
                        div{
                            border: 1px solid $lead;

                        }
                    }
                    &.slick-current{
                        div{
                            border: 1px solid $lead-alt;

                        }
                    }
                }
            }
        }

        .slider-arrow{
            background-color: $lead;
            color: #fff;
            padding: 0 12px;
            font-size: 27px;

            &-prev{
                left: 0;
                transform: translateX(-50%);
            }
            &-next{
                right: 0;
                transform: translateX(50%);
            }

            &:hover{
                background-color: #fff;
                color: $lead;
            }
        }
    }

    .product-shop{
        width: 100%;
        float: none;
        padding: 0;
    }

    .add-to-cart-wrapper{
        float: none;
        width: auto;
        padding: 0;
    }

    .product-text-block{
        font-size: 14px;
        margin-bottom: 30px;

        h2{
            font-size: 24px;
            font-weight: $regular;
            border-bottom: 1px solid $lead;
            color: $lead-alt;
            padding: 0 0 5px;
            text-transform: none;
            margin: 0 0 20px;
        }

        h3{
            font-size: 18px;
        }

        ul{
            padding: 0;
            li{
                position: relative;
                padding-left: 15px;
                margin-bottom: 5px;

                &:before{
                    content: "+";
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: $lead;
                    font-weight: $bold;
                    font-size: 14px;
                }
            }
        }
    }

    #product-attribute-specs-table th{
        border: 0;
    }

    #product-attribute-specs-table{
        border: 0;
    }

    .add-to-cart{
        border-bottom: 1px solid $lead;
    }

    .add-to-wrap{
        display: flex;
        flex-wrap: wrap;

        .add-to-cart{
            border: 0;
            margin: 0 0 10px;
            padding: 0;

            button{
                margin: 0;
                @extend .btn;
            }
        }

        .add-to{
            margin: 0 0 10px;
            padding: 0;

            a{
                display: inline-block;
            }
        }

    }

    .product-buy-options{
        display: flex;
        flex-wrap: wrap;
        clear: both;
        margin-bottom: 15px;

        .qty-wrapper{
            padding-right: 12px;
            padding-left: 0;

            .select{
                width: 110px;
            }
        }

        label{
            font-family: $font-family;
            font-weight: $regular;
            font-size: 12px;
            margin-bottom: 5px;
        }
        .product-options-bottom{
            display: none;
        }

        .product-options{
            border: 0;
            margin: 0 -6px;
            padding: 0;
            width: auto;

            p.required{
                display: none;
            }

            dt {
                margin-bottom: 0;
            }

            label{
                &.required {
                    &:after {
                        font-size: 11px;
                    }
                }
            }

            .product-options-list{
                display: flex;
                flex-wrap: wrap;

                .product-options-list-item{
                    position: relative;
                    padding: 0 6px 6px;

                    select{
                        max-width: none;
                        width: calc(100% + 30px);
                    }

                    .input-box{
                        padding: 0;
                    }


                }

                ul{
                    margin: 0;
                    padding: 0;
                }

            }

            .checkbox{
                position: absolute;
                z-index: -1;
                opacity: 0;
                visibility: hidden;

                + .label{
                    label{
                        border: 1px solid $lead-alt;
                        background-color: transparent;
                        width: 210px;
                        padding: 10px 35px 10px 35px;
                        position: relative;
                        cursor: pointer;
                        color: $gray;
                        font-size: 12px;
                        font-weight: $regular;
                        line-height: 1;
                        min-height: 45px;
                        display: flex;
                        align-items: center;

                        &:before{
                            content: "";
                            position: absolute;
                            left: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            border: 1px solid $gray;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                &:checked{
                    + .label{
                        label{
                            &:before{
                                background-color: $lead;
                                border-color: $lead;
                            }
                        }
                    }
                }
            }
        }
    }

    .special-services-contact{
        font-size: 14px;
        width: 270px;
        margin-bottom: 30px;

        p:first-child{
            color: #fff;
            background-color: $lead;
            position: relative;
            padding: 10px 15px;
            margin-bottom: 10px;

            @extend .tooltip;
        }

        p:nth-child(2){
            color: $gray;
            font-size: 36px;
            font-weight: $light;

            i{
                font-size: 18px;
                vertical-align: middle;
            }
        }
    }

    .sales-contact{
        background-color: $lead-alt;
        background-position: right 10% bottom 50px;
        background-repeat: no-repeat;
        color: #fff;
        font-size: 18px;
        width: 440px;
        max-width: 100%;

        @media (min-width: $screen-xs-min){
            background-image: url(../images/chat.png);
        }

        h2{
            background-color: $lead;
            color: #fff;
            font-size: 18px;
            padding: 20px 15px;
            @extend .tooltip;
            margin: 0;

            @media (min-width: $screen-sm-min){
                padding: 20px 30px;
            }
        }

        ul{
            padding: 30px 15px;

            @media (min-width: $screen-sm-min){
                padding: 30px 30px;
            }

            li{
                margin-bottom: 10px;
            }
        }


        a[href^="tel:"] {
            font-size: 30px;
            font-weight: $light;

            i,em{
                display: inline-block;
                transform: translateY(-5px);
            }
        }

        a{
            &:hover{
                color: $lead;
            }
        }
        i,em{

            font-size: 16px;
            color: $lead;
        }
    }
}
