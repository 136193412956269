.account-create, .customer-account-forgotpassword{
    .fieldset{
        margin: 0;
        width: 380px;
        max-width: 100%;

        input{
            width: 100%;
        }
    }

    .buttons-set{
        margin-left: 0;
        width: 100%;
        max-width: 380px;
    }
}

.account-create{
    .fieldset{
        .required{
            float: none;
        }
    }

    .input-box {
        .checkbox {
            width: auto;
        }
    }
}

.giftplus-card-status {
    margin-top: 1rem;

    dt, dd {
        font-size: 1.2rem;
    }

    dd {
        margin-bottom: 1rem;
        font-size: 1.75rem;
    }
}
