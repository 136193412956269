#contact-page{
    .contact-content{
        display: flex;
        flex-wrap: wrap;
        margin: 0 $grid-gutter-width / -2;
        padding-bottom: 80px;

        > *{
            margin-bottom: 10px
        }

        p{
            margin-bottom: 20px;
        }

        .contact-service{
            color: #fff;
            padding: 0 $grid-gutter-width / 2;
            order: 1;

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
                width: 50%;
                order: 1;
            }

            @media (min-width: $screen-md-min){
                width: 390px;
            }

            .block-content{
                background-color: $lead;
                padding: 30px 15px 20px;

                @media (min-width: $screen-sm-min){
                    padding: 80px 35px 40px;
                }
            }

            h2{
                font-size: 24px;
                font-weight: $regular;
                text-transform: none;
                margin: 0 0 20px;
            }

            a{
                font-size: 24px;
                font-weight: $light;
            }
        }

        .contact-unit{
            color: $gray;
            font-size: 14px;
            line-height: 1.3;
            padding: 0 $grid-gutter-width / 2;
            order: 2;

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
                width: 100%;
                order: 3;
            }

            @media (min-width: $screen-md-min){
                width: 25%;
            }

            .block-content{
                @media (min-width: $screen-md-min){
                    padding-left: 40px;
                }
            }

            h2{
                color: $gray;
                font-size: 18px;
                font-weight: $regular;
                text-transform: none;
                margin: 20px 0;
            }

            h3{
                color: $lead-alt;
                font-weight: $bold;
                font-size: 14px;
                margin: 0 0 2px;
                text-transform: none;
            }

            h4{
                font-size: 14px;
                font-weight: $medium;
                font-style: italic;
                margin: 0 0 2px;
                text-transform: none;
            }

            a{
                color: $lead;

                &:hover{
                    color: $lead-alt;
                }
            }
        }

        .contact-info{
            padding: 0 $grid-gutter-width / 2;
            order: 3;
            width: 100%;
            line-height: 1.15;

            @media (min-width: $screen-md-min){

            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
                width: 50%;
                order: 2;
            }

            @media (min-width: $screen-md-min){
                margin-left: auto;
                width: 33.33%;
                display: flex;
                justify-content: flex-end;
            }

            img{
                display: block;
                margin-bottom: 40px;
            }

            .block-content{
            }
        }
    }
}
