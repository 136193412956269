.notice-cookie{
    background-color: $lead-alt;
    color: #fff;
    width: 220px;
    padding: 15px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 0;
    border: 0;
    margin: 0;
    font-size: 13px;
    z-index: 100;

    .notice-inner{
        padding: 0;
        background: none;
    }
    .notice-text{
        padding-bottom: 5px;
        max-width: 100%;
        float: none;

        p{
            padding: 0;
            margin: 0 0 10px;
        }
    }

    a{
        font-weight: bold;
    }

    .actions{
        float: none;
        padding: 0;
    }

    .button{
        background-color: $lead;
        color: #fff;
        display: block;
        width: 100%;
        padding: 11px;
        border: 1px solid $lead;

        &:hover{
            @media (min-width: $screen-md-min){
                background-color: #fff;
                color: $lead;
            }
        }
    }
}
