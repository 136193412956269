.cms-home{

    #home-slider{
        margin-bottom: 50px;
        @media (min-width: $menu-breakpoint){
            margin-top: -23px;
        }

        .slider-arrow{

            @media (min-width: $screen-sm-min){
                top: 65%;
                font-size: 64px;
            }

            @media (min-width: $screen-lg-min){
                &-prev{
                    left: calc( (100vw - #{$wrapper}) / 2 );
                }

                &-next{
                    right: calc( (100vw - #{$wrapper}) / 2 );
                }
            }

            @media (min-width: $screen-xl-min){
                &-prev{
                    left: 140px;
                }

                &-next{
                    right: 140px;
                }
            }
        }
    }

    .home-posts{
        margin-bottom: 70px;

        .news{
            margin: 0 0 30px;
        }

        .show-more{
            z-index: 2;
            position: relative;

            &:before{
                content: "";
                position: absolute;
                z-index: -1;
                height: 2px;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background-color: $lead-alt;
            }
            &-btn{
                display: inline-block;
                background-color: #fff;
                padding: 14px;
                color: $lead;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: $light;

            }
        }
    }
}
