
.pager{
    float: none;
    clear: none;
    text-align: right;
    .pages {
        float: none;
        display: inline-block;
    }

    ol {
        li {
            color: $lead;
            font-family: inherit;
            font-size: 18px;
            font-weight: $medium;
            a {
                font: inherit;
                color: $lead;
                &:hover {
                    opacity: .5;
                    border-color: transparent;
                }
            }
            &.current {
                color: $lead-alt;
                border-color: transparent;
                background-color: transparent;
                font-family: inherit;
                font-size: 18px;

                &:hover{
                    border-color: transparent;
                }
            }
            .next,
            .previous {
                font-size: 0;
                border: 0;

                &:hover{
                    border: 0;
                }
            }
            .previous {
                &:before {
                    border-right-color: $lead;
                }
            }
            .next {
                &:before {
                    border-left-color: $lead;
                }
            }
        }
    }
}
